// src/redux/codeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { database } from "../../appWrite";

// Async thunk to fetch the properties from the server
export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async () => {
    const response = await database.listDocuments(
      "67170772002d46514efd", // Your database ID
      "67170809001424537d86" // Your collection ID for properties
    );

    // Map the documents to the Property type
    const allProperties = response.documents.map((doc) => ({
      _id: doc.$id,
      images: doc.images, // Ensure that 'images' exists on your document model
      title: doc.title,
      price: doc.price,
      category: doc.category,
      description: doc.description,
      location: doc.location,
      condition: doc.condition,
      features: doc.features,
      bedrooms: doc.bedrooms,
      bathrooms: doc.bathrooms,
    }));

    return allProperties;
  }
);

const allPropertySlice = createSlice({
  name: "properties",
  initialState: {
    allProperties: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allProperties = action.payload; // Corrected this line
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default allPropertySlice.reducer;
