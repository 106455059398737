// AdminProperties.tsx
import React, { useEffect, useState } from "react";
import {
  FaHome,
  FaSignOutAlt,
  FaBars,
  FaTimes,
  FaUsers,
  FaUserTie,
  FaBuilding,
  FaArrowLeft,
  FaCreditCard,
  FaUserSecret,
} from "react-icons/fa";
import useScrollToTop from "../../../components/useScrollToTop";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import BottomNavbar from "../../../components/BottomNavbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchProperties } from "../../../redux/slices/AllPropertySlice";
import logo from "../../../assets/img/opulent.png";
import { database, storage } from "../../../appWrite";

const EditPropertyModal = ({ property, isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormData(property);
  }, [property]);

  const handleChange = (e) => {
    if (formData) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData) {
      onSubmit(formData);
    }
  };

  if (!isOpen || !formData) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Edit Property</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Price</label>
            <input
              type="text"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-gray-700">Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div> */}
          {/* <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div> */}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-600 text-white rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, isDeleting }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Confirm Delete</h2>
        <p className="mb-6">Are you sure you want to delete this item?</p>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-600 text-white rounded"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded"
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};

const PropertyCard = ({ property, onEdit, onDelete }) => (
  <motion.div
    whileHover={{ scale: 1.01 }}
    className="bg-white shadow-md rounded-lg overflow-hidden"
  >
    <img
      src={property.images[0]}
      alt={property.title}
      className="w-full h-48 object-cover"
    />
    <div className="p-4">
      <h3 className="text-lg font-bold">{property.title}</h3>
      <p className="text-gray-600">{property.location}</p>
      <p className="text-gray-900 font-semibold">{property.price}</p>
      <div className="mt-4 flex justify-between">
        <button
          onClick={() => onEdit(property)}
          className="text-blue-500 hover:text-blue-700"
        >
          Edit
        </button>
        <button
          onClick={() => onDelete(property)}
          className="text-red-500 hover:text-red-700"
        >
          Delete
        </button>
      </div>
    </div>
  </motion.div>
);

const AdminProperties = () => {
  useScrollToTop();
  const State = useSelector((state) => state);
  const { allProperties: properties } = State.AllProperties;
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedProperty(null);
  };

  const databaseId = "67170772002d46514efd"; // Your database ID
  const collectionId = "67170809001424537d86"; // Your collection ID

  const handleEditSubmit = async (updatedProperty) => {
    setIsLoading(true);

    try {
      const updateData = {
        title: updatedProperty.title,
        price: updatedProperty.price,
        // Add other fields you need to update here
      };

      // Update the item in Appwrite
      await database.updateDocument(
        databaseId,
        collectionId,
        updatedProperty._id,
        updateData
      );

      alert("Item updated!");

      // Fetch updated items after successful update
      dispatch(fetchProperties());
      setIsLoading(false);
      setModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Error updating the item:", error);
      setIsLoading(false);
      alert("There was an error updating the item. Please try again.");
    }
  };

  const handleDelete = (property) => {
    setPropertyToDelete(property);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (propertyToDelete) {
      const { _id, images } = propertyToDelete; // Get images as well
      try {
        setIsDeleting(true);

        // Delete each image from the storage, if there are images
        if (images && images.length > 0) {
          const deleteImagePromises = images.map(async (imageUrl) => {
            try {
              // Extract file ID from the image URL (assuming URLs have format like '/files/{fileId}')
              const imageId = imageUrl.split("/").slice(-2, -1)[0]; // Gets the part just before '/view'

              // Delete the file using its ID
              await storage.deleteFile("671712420028ae91734c", imageId);
            } catch (error) {
              console.error(`Error deleting image with ID ${imageUrl}:`, error);
            }
          });
          await Promise.all(deleteImagePromises);
        }

        // Now delete the property document
        await database.deleteDocument(
          "67170772002d46514efd", // Your database ID
          "67170809001424537d86", // Your collection ID
          _id
        );

        alert("Item deleted!");
        setIsDeleting(false);
        dispatch(fetchProperties());
        setDeleteModalOpen(false);
        setPropertyToDelete(null);
      } catch (error) {
        console.error("Error deleting property:", error);
        setIsDeleting(false);
        setDeleteModalOpen(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setPropertyToDelete(null);
  };

  return (
    <div className="flex overflow-x-hidden max-[450px]:block min-h-screen w-full bg-gray-800 pb-[4rem]">
      {isLoading ? (
        <div>
          <img src={logo} alt="logo" className="w-[4rem] h-[4rem] z-50" />
        </div>
      ) : null}
      {/* Sidebar */}
      <div
        className={`fixed md:static inset-0 z-50 bg-gray-600 lg:bg-gray-50 shadow w-64 lg:ml-[2%] lg:mt-[1.5%] lg:rounded-lg p-6 transition-transform duration-300 ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full md:translate-x-0"
        }`}
      >
        <h1 className="text-xl md:text-3xl  pt-6 md:pt-0 max-[450px]:text-white font-bold mb-10 pl-2">
          Admin Dashboard
        </h1>
        <ul className="space-y-6">
          <Link to={"/admin-dashboard"}>
            <li
              className="flex items-center space-x-3 cursor-pointer md:text-black md:hover:text-white md:hover:bg-gray-600 hover:bg-gray-800 text-white py-1 pl-2"
              onClick={() => setSidebarOpen(false)}
            >
              <FaHome />
              <span>Overview</span>
            </li>
          </Link>
          <Link to={"/"}>
            <li
              className="flex items-center space-x-3 mt-4 cursor-pointer text-white md:text-black md:hover:text-white md:hover:bg-gray-600 hover:bg-gray-800 py-1 pl-2"
              onClick={() => setSidebarOpen(false)}
            >
              <FaArrowLeft />
              <span>Back Home</span>
            </li>
          </Link>

          <Link to={"/admin-properties"}>
            <li className="flex items-center space-x-3 mt-5 cursor-pointer text-white py-1 bg-gray-800 md:bg-gray-600 pl-2">
              <FaBuilding />
              <span>Properties</span>
            </li>
          </Link>
          <Link to={"/admin-residents"}>
            <li className="flex items-center space-x-3 mt-5 cursor-pointer xt-black md:hover:text-white md:hover:bg-gray-600 hover:bg-gray-800 md:text-black text-white py-1 pl-2">
              <FaUsers />
              <span>Residents</span>
            </li>
          </Link>
          <Link to={"/admin-staff"}>
            <li className="flex items-center space-x-3 mt-5 cursor-pointer text-white md:text-black md:hover:text-white md:hover:bg-gray-600 hover:bg-gray-800 py-1 pl-2">
              <FaUserTie />
              <span>Staff</span>
            </li>
          </Link>
          <Link to={"/admin-payments"}>
            <li className="flex items-center space-x-3 mt-5 cursor-pointer md:text-black md:hover:text-white md:hover:bg-gray-600 hover:bg-gray-800 text-white py-1 pl-2">
              <FaCreditCard />
              <span>Payments</span>
            </li>
          </Link>
          <Link to={"/admin-code"}>
            <li className="flex items-center space-x-3 mt-5 cursor-pointer md:text-black md:hover:text-white md:hover:bg-gray-600 hover:bg-gray-800 text-white py-1 pl-2">
              <FaUserSecret />
              <span>Entry Code</span>
            </li>
          </Link>
          <li
            className="flex items-center space-x-3 cursor-pointer md:text-black md:hover:text-white md:hover:bg-gray-600 hover:bg-gray-800 text-white py-1 pl-2"
            onClick={() => {
              // handleLogout();
              setSidebarOpen(false);
            }}
          >
            <FaSignOutAlt />
            <span>Logout</span>
          </li>
        </ul>
      </div>

      {/* Toggle Button */}
      <button
        className="fixed top-4 left-4 md:hidden bg-gray-800 text-white p-2 rounded-full z-50"
        onClick={toggleSidebar}
      >
        {sidebarOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Main Content */}
      <div className="min-h-screen">
        <main className="p-8">
          <h1 className="text-2xl font-bold mb-6 mt-3 lg:mt-0 text-white">
            Properties
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {properties.map((property) => (
              <PropertyCard
                key={property._id}
                property={property}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))}
          </div>
        </main>
      </div>

      <BottomNavbar current={"dashboard"} />

      {modalOpen && selectedProperty && (
        <EditPropertyModal
          isOpen={modalOpen}
          onClose={handleModalClose}
          property={selectedProperty}
          onSubmit={handleEditSubmit}
        />
      )}
      <ConfirmDeleteModal
        isOpen={deleteModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default AdminProperties;
