import { Client, Account, Databases, Storage } from "appwrite";

const client = new Client();
client.setProject("67170702001c79b599d2");

const account = new Account(client);
const database = new Databases(client);
const storage = new Storage(client);

export { client, account, database, storage };
